import React from 'react';
import Card from '../Card/Card';
import styles from './Contact.module.css';

const Contact = () => {
  return (
    <Card>
      <div className={styles.container}>Contact</div>
    </Card>
  );
};

export default Contact;
