import React, { ReactNode } from 'react';
import styles from './Card.module.css';

const Card = ({ children }: CardProps) => {
  return <div className={styles.container}>{children}</div>;
};

export default Card;

interface CardProps {
  children: ReactNode;
}
