import React from 'react';
import Card from '../Card/Card';
import styles from './Home.module.css';

const Home = () => {
  return (
    <Card>
      <div className={styles.container}>Welcome to Home Page</div>
    </Card>
  );
};

export default Home;
