import React, { useState } from 'react';
import styles from './LoginForm.module.css';
import { Button } from '../Button/Button';
import Card from '../Card/Card';
const LoginForm = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState(true || false);
  const userNameIndexOf = userName.indexOf('@');
  const userNameLastIndex = userName.lastIndexOf('.');

  const checkValidation = () => {
    if (userName.length < 6) {
      setMessageColor(true);
      setMessage('Nazwa użytkownika jest za krótka.');
    } else if (userName.length > 20) {
      setMessageColor(true);
      setMessage('Nazwa użytkownika jest za długa.');
    } else if (userName === password) {
      setMessageColor(true);
      setMessage('Nazwa użytkownika i hasło nie mogą być takie same.');
    } else if (userNameIndexOf < 1 || userNameLastIndex - userNameIndexOf < 2) {
      setMessageColor(true);
      setMessage('Podaj poprawny adres email.');
    } else if (password.search(/[a-z]/) < 0) {
      setMessageColor(true);
      setMessage('Hasło musi zawierać conajmniej jedną małą literę.');
    } else if (password.search(/[A-Z]/) < 0) {
      setMessageColor(true);
      setMessage('Hasło musi zawierać conajmniej jedną dużą literę.');
    } else if (password.search(/[0-9]/) < 0) {
      setMessageColor(true);
      setMessage('Hasło musi zawierać conajmniej jedną cyfrę.');
    } else {
      setMessageColor(true);
      setMessage('Nie można zalogować');
    }
  };

  const handleClick = () => {
    if (userName === 'foxtrzysta@gmail.com' && password === 'Kamil15332') {
      setMessageColor(false);
      setMessage('Zostałeś poprawnie zalogowany');
    } else {
      checkValidation();
    }
  };

  return (
    <Card>
      <div className={styles.container}>
        <label>Username</label>
        <input
          type="text"
          onChange={(e) => setUserName(e.target.value)}
          placeholder="Enter e-mail"
        ></input>
        <label>Password</label>
        <input
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
        ></input>
        <p
          className={
            messageColor === true ? styles.errorMessage : styles.corectMesage
          }
        >
          {' '}
          {message}{' '}
        </p>
        <Button color="orange" onClick={handleClick}>
          Login
        </Button>
      </div>
    </Card>
  );
};

export default LoginForm;
