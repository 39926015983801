import React from 'react';
import Card from '../Card/Card';
import styles from './About.module.css';

const About = () => {
  return (
    <Card>
      <div className={styles.container}>About</div>
    </Card>
  );
};

export default About;
