import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './Weather.module.css';
import { Button } from '../Button/Button';
import Card from '../Card/Card';

const apiKey = 'a9faaca4a7d125a6a9b6e32fdec3205a';
const link = 'https://api.openweathermap.org/data/2.5/weather?';

const townGeoPositionMapping: Record<string, { lat: number; lon: number }> = {
  poznan: { lat: 52.409538, lon: 16.931992 },
  warszawa: { lat: 52.22977, lon: 21.01178 },
  wroclaw: { lat: 51.107883, lon: 17.038538 },
};

const Wheater = () => {
  const [refreshResponse, setRefreshResponse] = useState(true);
  const [loading, setLoading] = useState(false);
  const [temperature, setTemperature] = useState('');
  const refreshResponseTrigger = () => {
    setRefreshResponse(!refreshResponse);
  };

  const { town } = useParams();

  const coordinates = town && townGeoPositionMapping[town];
  if (!coordinates) return null;

  useEffect(() => {
    const request = async () => {
      const url = `${link}lat=${coordinates.lat}&lon=${coordinates.lon}&appid=${apiKey}&units=metric&lang=pl`;
      setLoading(true);
      // fetch(url)
      //   .then((response) => response.json())
      //   .then((response) => {
      //     setTemperature(response.main.temp);
      //     setLoading(false);
      //   });

      // await new Promise((resolve) => setTimeout(resolve, 1000));

      const response = await fetch(url);
      const json = await response.json();
      setTemperature(json.main.temp);
      setLoading(false);
    };

    request();
  }, [town, refreshResponse]);

  return loading ? (
    <Card>
      <div className={styles.loading}>Downloading data, please wait...</div>
    </Card>
  ) : (
    <Card>
      <>
        <div className={styles.container}>
          <div className={styles.data}>
            Temperatura dla miasta{' '}
            {town.charAt(0).toUpperCase() + town.slice(1)} to {temperature}{' '}
            stopni Celciusza.
          </div>
          <Button color="orange" onClick={refreshResponseTrigger}>
            Refresh
          </Button>
        </div>
      </>
    </Card>
  );
};

export default Wheater;
