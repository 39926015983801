import React, { ReactNode, createContext, useState } from 'react';
import NavBar from '../NavBar/NavBar';
import Sidebar from '../Sidebar/Sidebar';
import styles from './Layout.module.css';

export const TitleContext = createContext<Context>({} as Context);

const Layout = ({ children }: LayoutProps) => {
  const [title, setTitle] = useState('');

  const context = {
    title,
    setTitle,
  };
  return (
    <TitleContext.Provider value={context}>
      <div className={styles.container}>
        <div className={styles.leftContent}>
          <Sidebar />
        </div>
        <div className={styles.rightContent}>
          <div className={styles.upperContent}>
            <NavBar />
          </div>
          <div className={styles.downContent}>{children}</div>
        </div>
      </div>
    </TitleContext.Provider>
  );
};

export default Layout;

interface LayoutProps {
  children: ReactNode;
}
interface Context {
  title: string;
  setTitle: (title: string) => void;
}
