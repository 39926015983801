import React, { useState } from 'react';
import { Logo } from '../../assets/icons';
import Dropdown from './Dropdown';
import styles from './Sidebar.module.css';
import { SidebarData } from './SidebarData';

const Sidebar = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toogleExpanded = () => setIsExpanded(!isExpanded);

  return (
    <>
      <div className={isExpanded ? styles.sidebar : styles.sidebarWidth}>
        <Logo className={styles.logo} />
        <ul className={styles.sidebarList}>
          {SidebarData.map((val) => {
            return (
              <Dropdown
                link={val.link}
                SubItems={val.SubItems}
                icon={val.icon}
                title={val.title}
              />
            );
          })}
        </ul>
        {/* <div>
          <Button onClick={toogleExpanded}>Change</Button>
        </div> */}
      </div>
    </>
  );
};
export default Sidebar;
