import React, { useState, useEffect } from 'react';
import Card from '../Card/Card';
import styles from './Clock.module.css';

function Clock() {
  const [time, setTime] = useState(new Date());

  const refreshClock = () => {
    setTime(new Date());
  };

  useEffect(() => {
    const timerId = setInterval(refreshClock, 10);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const hours = time.getHours();
  const minutes = time.getMinutes().toString().padStart(2, '0');
  const seconds = time.getSeconds().toString().padStart(2, '0');
  const miliseconds = time.getMilliseconds().toString().padStart(3, '0');

  const hoursColor =
    hours >= 2 && hours < 8
      ? styles.red
      : hours >= 0 && hours < 2
      ? styles.orange
      : styles.blue;

  const secondsColor = Number(seconds) % 2 === 0 ? styles.green : styles.purple;
  const minutesColor = styles.brown;

  return (
    <Card>
      <div className={styles.clock}>
        <span className={hoursColor}>{hours}</span>:
        <span className={minutesColor}>{minutes}</span>:
        <span className={secondsColor}>{seconds}</span>:
        <span>{miliseconds}</span>
      </div>
    </Card>
  );
}

export default Clock;
