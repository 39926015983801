import React, { useContext } from 'react';
import styles from './NavBar.module.css';
import { Point, Bels, Photo, Loup } from '../../assets/icons';
import { TitleContext } from '../Layout/Layout';

const NavBar = () => {
  const context = useContext(TitleContext);
  return (
    <div className={styles.container}>
      <div className={styles.title}>{context.title}</div>

      <div className={styles.inputDiv}>
        <div className={styles.loup}>
          <Loup />
        </div>
        <input type="search" placeholder="Search..."></input>
      </div>
      <div className={styles.containerIcon}>
        <div className={styles.pointIcon}>
          <Point />
        </div>
        <div className={styles.belsIcon}>
          <Bels />
        </div>
        <div className={styles.userPhoto}>
          <Photo />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
