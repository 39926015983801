import { ComponentsIcon, Icon1, Icon2, MenuIcon } from '../../assets/icons';
import { MenuItem } from './Dropdown';

export const SidebarData: MenuItem[] = [
  {
    title: 'Home',
    icon: ComponentsIcon,
    link: '/',
  },
  {
    title: 'Components',
    icon: MenuIcon,
    SubItems: [
      {
        title: 'Weather',
        SubItems: [
          {
            title: 'Poznań',
            link: '/weather/poznan',
          },
          {
            title: 'Warszawa',
            link: '/weather/warszawa',
          },
          {
            title: 'Wrocław',
            link: '/weather/wroclaw',
          },
        ],
      },
      {
        title: 'Clock',
        link: '/clock',
      },
      {
        title: 'Login',
        link: '/login-page',
      },
      {
        title: 'ToDo List',
        link: '/to-do-list',
      },
    ],
  },
  {
    title: 'About me',
    icon: Icon2,
    link: '/about',
  },
  {
    title: 'Contact',
    icon: Icon1,
    link: '/contact',
  },
];
