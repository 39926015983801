import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Dropdown.module.css';
import { DropdownItem } from './DropdownItem';
import { TitleContext } from '../Layout/Layout';

export interface MenuItem {
  link?: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  SubItems?: MenuItem[];
  isSubItem?: boolean;
}

function Dropdown(props: MenuItem) {
  const context = useContext(TitleContext);

  const [isUnfolded, setIsUnfloded] = useState(false);
  const toggleFolded = () => {
    setIsUnfloded(!isUnfolded);
  };

  const navigate = useNavigate();
  const navigateToLink = () => {
    props.link && navigate(props.link);
    context.setTitle(props.title);
  };

  const hasSubItems = !!props.SubItems;

  return (
    <>
      <DropdownItem
        onClick={hasSubItems ? toggleFolded : navigateToLink}
        link={props.link}
        icon={props.icon}
        title={props.title}
      />

      {hasSubItems && isUnfolded && (
        <div className={styles.dropdownUnfolding}>
          {props.SubItems?.map((subItem) => (
            <Dropdown
              title={subItem.title}
              icon={subItem.icon}
              link={subItem.link}
              SubItems={subItem.SubItems}
              key={subItem.link}
              isSubItem
            />
          ))}
        </div>
      )}
    </>
  );
}
export default Dropdown;
