import React, { ReactNode } from 'react';
import styles from './Button.module.css';
import classNames from 'classnames';

export function Button({ children, color = 'blue', onClick }: ButtonProps) {
  return (
    <button
      onClick={onClick}
      className={classNames(styles.button, styles[color])}
    >
      {children}
    </button>
  );
}

interface ButtonProps {
  children: ReactNode;
  onClick: VoidFunction;
  color?: 'orange' | 'blue' | 'red';
}
