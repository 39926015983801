import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import LoginForm from './Components/LoginForm/LoginForm';
import Clock from './Components/Clock/Clock';
import ToDo from './Components/ToDo/ToDo';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import Weather from './Components/Weather/Weather';
import Layout from './Components/Layout/Layout';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/weather/:town" element={<Weather />} />
          <Route path="/clock" element={<Clock />} />
          <Route path="/to-do-list" element={<ToDo />} />
          <Route path="/login-page" element={<LoginForm />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
