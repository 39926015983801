import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import styles from './DropdownItem.module.css';

export const DropdownItem = ({
  onClick,
  link,
  icon: IconComponent,
  title,
}: DropdownItemProps) => {
  const location = useLocation();

  return (
    <li
      className={classNames(
        styles.dropdownItem,
        location.pathname === link && styles.active
      )}
      onClick={onClick}
    >
      <div className={styles.iconContainer}>
        {IconComponent && <IconComponent className={styles.icon} />}
      </div>
      <div className={styles.title}>{title}</div>
    </li>
  );
};

interface DropdownItemProps {
  onClick: VoidFunction;
  link?: string;
  title: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}
