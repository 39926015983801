import React, { useState } from 'react';
import styles from './ToDo.module.css';
import HorizontalSeparator from '../HorizontalSeparator/HorizontalSeparator';
import { Button } from '../Button/Button';
import Card from '../Card/Card';

const ToDo = () => {
  const [inputValue, setInputValue] = useState('');
  const [tasks, setTasks] = useState<Task[]>([]);

  interface Task {
    id: number;
    text: string;
    isDone: boolean;
  }

  const addTask = () => {
    if (inputValue.length > 0) {
      const highestId = tasks.reduce(function (prev, current) {
        return prev > current.id ? prev : current.id;
      }, 0);
      const nextId = highestId + 1;
      setTasks((tasks) => [
        ...tasks,
        { id: nextId, text: inputValue, isDone: false },
      ]);
      setInputValue('');
    }
  };

  const deleteAllTask = () => {
    setTasks([]);
  };

  const checkSwitch = (id: number) => {
    const task = tasks.find((task) => task.id === id);
    if (!task) {
      return;
    }
    task.isDone = !task.isDone;
    setTasks([...tasks]);
  };

  const deleteTask = (id: number) => {
    const newTasks = tasks.filter((task) => task.id !== id);
    setTasks(newTasks);
  };

  return (
    <Card>
      <div className={styles.box}>
        <div className={styles.boxChild}>
          <input
            onChange={({ target: { value } }) => setInputValue(value)}
            onKeyDown={({ keyCode }) => keyCode === 13 && addTask()}
            value={inputValue}
            type="text"
            placeholder="Add task..."
          ></input>
          <div className={styles.buttonSection}>
            <Button color="orange" onClick={addTask}>
              Add task
            </Button>
            <Button color="orange" onClick={deleteAllTask}>
              Delete all tasks
            </Button>
          </div>
        </div>
        <HorizontalSeparator />
        <ul>
          {tasks
            .sort((a, b) => {
              return a.isDone === b.isDone ? 0 : a.isDone ? 1 : -1;
            })
            .map((task) => {
              return (
                <li className={styles.taskList}>
                  <div className={styles.task}>{task.text}</div>
                  <div className={styles.taskHandle}>
                    <input
                      className={styles.checkBox}
                      type="checkbox"
                      onChange={() => checkSwitch(task.id)}
                      checked={task.isDone}
                    ></input>
                    <button onClick={() => deleteTask(task.id)}>x</button>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </Card>
  );
};

export default ToDo;
